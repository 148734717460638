import { Box } from "@mui/material";
import type { ReactNode } from "react";
import React, { useState } from "react";

import { Header } from "../Header";
import { Sidebar } from "../Sidebar";

interface IComponentWrapper {
  children: ReactNode;
}

export const ComponentWrapper: React.FC<IComponentWrapper> = ({
  children,
}: any) => {
  const [open, setOpen] = useState(true);
  return (
    <Box height="100vh" display="flex">
      <Sidebar toggle={open} setToggle={setOpen} />
      <Box flexGrow={1} overflow="auto">
        <Header open={open} setOpen={setOpen} />
        <Box p={3}>{children}</Box>
      </Box>
    </Box>
  );
};
