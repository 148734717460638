import React from "react";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { Modal } from "../Modal";
import Loader from "../Loader";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => Promise<{}>;
  onSuccess?: (res: any) => void;
  title?: string;
  candidateData?: Record<string, any>;
  buttonText?: {
    secondary?: string;
    primary?: string;
  };
};

export const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  onSuccess,
  title = "Confirm your action",
  buttonText = {
    secondary: "Cancel",
    primary: "Confirm",
  },
}: Props) => {
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = () => {
    setLoading(true);

    onConfirm?.()
      .then((res) => {
        onSuccess?.(res);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    return () => setLoading(false);
  }, []);
  return (
    <>
      <Modal open={open} handleClose={onClose} title={title}>
        <Grid container gap={3} justifyContent={"center"} mt={1}>
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              {buttonText.secondary}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleConfirm}>
              {!loading ? buttonText.primary : <Loader size={20} />}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
