export const TENANT_PAYLOAD_KEYS = {
  name: "name",
  contactPersonName: "contactPersonName",
  gstName: "gstName",
  gstNumber: "gstNumber",
  modules: "modules",
  email: "email",
  mobile: "mobile",
  website: "website",
  logo: "logo",
  documents: "documents",
  address: "address",
  pricing: "pricing",
  oneTime: "oneTime",
  perMonth: "perMonth",
};

export const TENANT_FIELD_NAMES = {
  name: TENANT_PAYLOAD_KEYS.name,
  email: TENANT_PAYLOAD_KEYS.email,
  contactPersonName: TENANT_PAYLOAD_KEYS.contactPersonName,
  mobile: TENANT_PAYLOAD_KEYS.mobile,
  website: TENANT_PAYLOAD_KEYS.website,
  gstName: TENANT_PAYLOAD_KEYS.gstName,
  gstNumber: TENANT_PAYLOAD_KEYS.gstNumber,
  address: TENANT_PAYLOAD_KEYS.address,
  logo: TENANT_PAYLOAD_KEYS.logo,

  module: "module",
  primary1: "branding.primary1",
  primary2: "branding.primary2",
  secondary1: "branding.secondary1",
  secondary2: "branding.secondary2",
  document_pan: "document_pan",
  document_gst: "document_gst",
  document_rc: "document_rc",
  oneTime: `${TENANT_PAYLOAD_KEYS.pricing}.${TENANT_PAYLOAD_KEYS.oneTime}`,
  perMonth: `${TENANT_PAYLOAD_KEYS.pricing}.${TENANT_PAYLOAD_KEYS.perMonth}`,
};
