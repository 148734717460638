import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import * as React from "react";

type Anchor = "top" | "left" | "bottom" | "right";

interface ITemporaryDrawer {
  anchor?: Anchor;
  heading: string;
  open: boolean;
  children: React.ReactNode;
  onClose: any;
  onSubmit?: (e: any) => void;
  onReset?: (e: any) => void;
  action?: boolean;
  disableGutter?: boolean;
  header?: React.ReactNode;
  size?: "lg" | "md" | "sm" | "xs";
}

export const TemporaryDrawer = ({
  heading,
  anchor = "right",
  open,
  onClose,
  children,
  disableGutter,
  header,
  size = "sm",
}: ITemporaryDrawer) => {
  const width = {
    xxs: "25%",
    xs: "35%",
    sm: "45%",
    md: "55%",
    lg: "65%",
    xlg: "75%",
  }[size];

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width } }}
    >
      {header || (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.75rem 1rem 0.75rem 2rem",
            background: "#F4F5F7",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">{heading}</Typography>
          <IconButton
            onClick={onClose}
            sx={{
              p: 0,
              backgroundColor: "transparent",
            }}
          >
            <Close sx={{ color: "#121212" }} />
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          overflow: "auto",
          height: "100%",
          p: disableGutter ? 0 : "2rem",
          pb: "2rem",
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};
