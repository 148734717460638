import { httpService } from "./AxiosInstance";

export const fetchForms = () => {
  return httpService.get("/tenant/widget");
};

export const fetchFormDetail = (id: string) => {
  return httpService.get(`/tenant/widget/${id}`);
};

export const createForm = (data: Record<string, any>) => {
  return httpService.post("/tenant/widget", data);
};

export const updateForm = (formId: string, data: Record<string, any>) => {
  return httpService.patch(`/tenant/widget/${formId}`, data);
};
