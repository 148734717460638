import React from "react";
import { Formik, getIn } from "formik";
import { Box, Button, FormLabel } from "@mui/material";
import { ColorPicker, Loader, UploadButton } from "../../shared/Components";
import { TENANT_FIELD_NAMES } from "./constant";
import { updateTenant, uploadFile } from "../../services";
import { createTenantPayload, getObjectDifferences } from "./TenantUtils";

const Branding = ({ initialValues = {}, onSuccess }: any) => {
  const [reset, setReset] = React.useState(false);

  const uploadDocument = (
    e: React.ChangeEvent<HTMLInputElement>,
    onSuccess: (res: Record<string, any>) => void
  ) => {
    if (e.currentTarget.files?.[0]) {
      const formData: any = new FormData();
      formData.append("file", e.currentTarget.files?.[0]);
      uploadFile(formData)
        .then(onSuccess)
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onReset = (setFieldValue: any) => {
    setFieldValue(TENANT_FIELD_NAMES.document_pan, null);
    setFieldValue(TENANT_FIELD_NAMES.document_gst, null);
    setReset((p) => !p);
  };

  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      const newValues = getObjectDifferences(values, initialValues);
      const payload = createTenantPayload(newValues);
      updateTenant(initialValues._id, payload).then((res) => {
        rs("");
        onSuccess({ ...values });
      });
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
          handleReset,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          isSubmitting,
        }) => (
          <form style={{ height: "100%" }} onSubmit={handleSubmit}>
            <Box
              sx={{ height: "100%" }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box className="form-group">
                  <FormLabel>Primary Color 1</FormLabel>
                  <ColorPicker
                    onChange={(value: any) =>
                      setFieldValue(
                        TENANT_FIELD_NAMES.primary1,
                        `#${value.hex}`
                      )
                    }
                    value={getIn(values, TENANT_FIELD_NAMES.primary1)}
                  />
                </Box>

                <Box className="form-group">
                  <FormLabel>Primary Color 2</FormLabel>
                  <ColorPicker
                    onChange={(value: any) =>
                      setFieldValue(
                        TENANT_FIELD_NAMES.primary2,
                        `#${value.hex}`
                      )
                    }
                    value={getIn(values, TENANT_FIELD_NAMES.primary2)}
                  />
                </Box>

                <Box className="form-group">
                  <FormLabel>Secondary Color 1</FormLabel>
                  <ColorPicker
                    onChange={(value: any) =>
                      setFieldValue(
                        TENANT_FIELD_NAMES.secondary1,
                        `#${value.hex}`
                      )
                    }
                    value={getIn(values, TENANT_FIELD_NAMES.secondary1)}
                  />
                </Box>

                <Box className="form-group">
                  <FormLabel>Secondary Color 2</FormLabel>
                  <ColorPicker
                    onChange={(value: any) =>
                      setFieldValue(
                        TENANT_FIELD_NAMES.secondary2,
                        `#${value.hex}`
                      )
                    }
                    value={getIn(values, TENANT_FIELD_NAMES.secondary2)}
                  />
                </Box>
                <Box className="form-group">
                  <FormLabel>Logo</FormLabel>
                  <UploadButton
                    name={TENANT_FIELD_NAMES.logo}
                    reset={reset}
                    allowedExtensions={["jpg", "jpeg", "png"]}
                    onChange={(e) =>
                      uploadDocument(e, (res: Record<string, any>) => {
                        setFieldValue(
                          TENANT_FIELD_NAMES.logo,
                          res.data.data?._id
                        );
                      })
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  gap={2}
                  display="flex"
                  sx={{
                    borderTop: "1px solid #ECECEC",
                    p: "1rem 2rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      onReset(setFieldValue);
                      handleReset();
                    }}
                    disabled={isSubmitting}
                    sx={{ flex: 1 }}
                    variant="outlined"
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    sx={{ flex: 1 }}
                    variant="contained"
                  >
                    {isSubmitting ? <Loader /> : "Save"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Branding;
