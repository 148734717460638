import * as Yup from "yup";
import { TENANT_PAYLOAD_KEYS } from "../pages/CreateTenant/constant";

export const PricingSchema = Yup.object().shape({
  [TENANT_PAYLOAD_KEYS.pricing]: Yup.object().shape({
    [TENANT_PAYLOAD_KEYS.perMonth]: Yup.string()
      .matches(/^[0-9]*$/, "Value should be a number")
      .required("Per month amount is required"),
    [TENANT_PAYLOAD_KEYS.oneTime]: Yup.string()
      .matches(/^[0-9]*$/, "Value should be a number")
      .required("One time amount is required"),
  }),
});
