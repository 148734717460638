import React from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MoreOptions = ({
  actions,
  row,
}: {
  actions: Record<string, any>;
  row: Record<string, any>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const show = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-controls={show ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={show ? "true" : undefined}
        onClick={handleClick}
        sx={{
          p: 0,
          backgroundColor: "transparent",
        }}
      >
        <MoreVertIcon sx={{ color: "#121212" }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={show}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(actions).map((key) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              actions[key]?.(row);
              setAnchorEl(null);
            }}
          >
            {key}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreOptions;
