import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PropsWithChildren } from "react";

export interface IModal {
  open: boolean;
  handleClose?: () => void;
  title?: string;
  maxWidth?: "xs" | "xl" | "sm" | "md" | "lg";
  width?: string | number;
}

export const Modal = ({
  open,
  handleClose,
  title,
  children,
  maxWidth = "xs",
  width,
}: PropsWithChildren<IModal>) => {
  return (
    <Dialog
      sx={{ zIndex: "initial" }}
      fullWidth
      maxWidth={maxWidth}
      slotProps={{
        backdrop : {
          style : {
            background : "rgba(0,0,0,0.7)"
          }
        }
      }}
      PaperProps={{
        sx: {
          maxWidth: width || "inherits",
        },
      }}
      open={open}
      scroll="body"
      onClose={handleClose}
    >
      {title && (
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {title}

          <IconButton
            onClick={handleClose}
            sx={{
              padding: "5px",
              width: "auto",
              height: "auto",
              color: "#121212",
            }}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
