import { Box, Button, Typography } from "@mui/material";
import React from "react";

export const FormErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          border: "1px solid red",
          color: "#db1d1d",
          borderRadius: "5px",
          background: "#ffd7d766",
        }}
      >
        <Typography variant="subtitle1">Something went wrong</Typography>
        <Box
          display={"flex"}
          gap={1}
          sx={{
            padding: "10px",
            border: "solid 1px grey",
            borderRadius: "5px",
            background: "#f3f3f3",
          }}
        >
          <Typography variant="subtitle2" color={"#4c4c4c"}>
            {error.message}
          </Typography>
        </Box>
        <br />
        <Button variant="outlined" color="error" onClick={resetErrorBoundary}>
          Reset Form
        </Button>
      </Box>
    </>
  );
};

export default FormErrorFallback;
