export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/";
export const FORM_BUILDER = "/form-builder";
export const FORM_BUILDER_ADD = "/form-builder/add";
export const TENANTS = "/tenants";
export const MANAGE_USER = "/manage-users";
export const ROLES_AND_PERMISSION = '/roles-and-permission'
export const MANAGE_TEAMS = '/manage-teams'
export const BRANCHES = '/branches'
export const SETTINGS = '/settings'