import { Formik } from "formik";
import { Box, Button, FormLabel, TextField } from "@mui/material";

import { TENANT_FIELD_NAMES } from "./constant";
import { updateTenant } from "../../services";
import { createTenantPayload, getObjectDifferences } from "./TenantUtils";
import { Loader } from "../../shared/Components";

const ContactDetails = ({ initialValues, onSuccess }: any) => {
  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      const newValues = getObjectDifferences(values, initialValues);
      const payload = createTenantPayload(newValues);

      updateTenant(initialValues._id, payload)
        .then((res) => {
          onSuccess({ ...values });
        })
        .catch(() => {})
        .finally(() => {
          rs("");
        });
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleSubmit,
          handleReset,
          values,
          isSubmitting,
          dirty,
        }) => (
          <form style={{ height: "100%" }} onSubmit={handleSubmit}>
            <Box
              sx={{ height: "100%" }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box className="form-group">
                  <FormLabel>Contact person name</FormLabel>
                  <TextField
                    onChange={handleChange}
                    value={values[TENANT_FIELD_NAMES.contactPersonName] || ""}
                    name={TENANT_FIELD_NAMES.contactPersonName}
                    fullWidth
                    required
                    placeholder="Enter Contact person name"
                    variant="outlined"
                  />
                </Box>
                <Box className="form-group">
                  <FormLabel>H.O. Address</FormLabel>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    required
                    value={values[TENANT_FIELD_NAMES.address] || ""}
                    name={TENANT_FIELD_NAMES.address}
                    placeholder="Enter H.O. Address"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  gap={2}
                  display="flex"
                  sx={{
                    borderTop: "1px solid #ECECEC",
                    p: "1rem 2rem",
                  }}
                >
                  <Button
                    onClick={handleReset}
                    sx={{ flex: 1 }}
                    variant="outlined"
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    sx={{ flex: 1 }}
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                  >
                    {isSubmitting ? <Loader /> : "Save"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ContactDetails;
