import { createTheme } from "@mui/material/styles";

import vars from "./variables";

const {
  primaryFont,
  lightGrey,
  grey,
  primary,
  primaryActive,
  primaryActiveLight,
  white,
  secondary,
} = vars;

const theme = createTheme({
  typography: {
    fontFamily: primaryFont,

    body1: {
      color: lightGrey,
      fontSize: "0.8125rem",
      lineHeight: "160%",
    },

    body2: {
      color: "#726E89",
      fontSize: "1rem",
      fontWeight: 500,
    },

    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#282730'
    },

    h3: {
      color: "#A3A5A9",
      fontSize: "0.8125rem",
      fontWeight: 700,
      textTransform: "uppercase",
      padding: "0.75rem 1.5rem",
    },

    h4: {
      color: "#121212",
      fontSize: "1.25rem",
      fontWeight: 700,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: ${grey};
        }
        .form-group + .form-group {
          margin-top: 1.5rem
        }
        *, body {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: ${primaryFont};
        }
      `,
    },

    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          // '& .MuiAvatar-root': {
          //   marginLeft: '-1.5rem'
          // }
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            justifyContent: 'flex-start'
          }
        }
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          justifyContent: 'space-between'
        },
        label: {
          color: '#5A6474',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: 'normal',
        },
      }
    },

    MuiFormGroup: {
      styleOverrides: {
        root: {
          rowGap: '1rem'
        }
      }
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.15)",
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: "90px",
          background: "rgba(0, 47, 95, 0.10)",
        },

        bar: {
          borderRadius: "90px",
          background: "#002F5F",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          color: "#646E88",
          marginTop: "0.62rem",
          fontSize: "0.875rem",
          lineHeight: "120%",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: primary,
          fontSize: "1rem",
          lineHeight: "120%",
        },
        root: {
          "&.Mui-focused": {
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: primaryActive,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              // borderWidth: '1px',
              borderColor: primaryActive,
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DDE1EA",
            },
          },
        },
        notchedOutline: {
          borderColor: "#DDE1EA",
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            color: "#5A6474",
            fontSize: "1rem",
            lineHeight: "120%",
          },
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          background: '#000',
        },

        dot: {
          border: "3px solid #fff",
          width: "1.25rem",
          height: "1.25rem",
          borderRadius: "50%",
        },

        colorSuccess: {
          background: "green",
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(231, 235, 249)",
        },
        label: {
          fontSize: "0.875rem",
          fontWeight: 500,
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0 1.5rem",
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "1rem 1.5rem",
        },
        content: {
          margin: 0,

          "& .MuiTypography-root": {
            color: "#646E88",
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "120%",
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:first-of-type": {
            borderRadius: 0,
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
        root: {
          color: "#282730",
          fontSize: "1rem",
          fontWeight: 400,
          display: "flex",
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginBottom: "0.625rem",
          lineHeight: "120%",

          '& a': {
            color: "#002F5F",
            fontSize: "1rem",
            fontWeight: 500,
            textDecorationLine: 'underline',
          },

          "&.Mui-focused": {
            color: "#646E88",
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "42.3125rem",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#5A6474",
          fontSize: "0.875rem",
          fontWeight: 500,

          "&.Mui-disabled": {
            display: "none",
          },

          "&.Mui-selected": {
            color: primaryActive,
            background: primaryActiveLight,

            "&:hover": {
              color: primaryActive,
              background: primaryActiveLight,
            },
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          color: secondary,
          "&.Mui-selected": {
            backgroundColor: primaryActiveLight,
            color: primaryActive,
            "&:hover": {
              backgroundColor: primaryActiveLight,
              color: primaryActive,
            },
            "&:focus": {
              backgroundColor: primaryActiveLight,
              color: primaryActive,
            },
          },
        },
        actions: {
          "& .MuiIconButton-root": {
            width: "auto",
            height: "auto",
            background: "transparent",
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {},
      },
    },

    MuiTableCell: {
      styleOverrides: {
        body: {
          color: "#121212",
          fontWeight: 500,
        },
        head: {
          color: "#5A6474",
          background: grey,
          "&:first-of-type": {
            borderTopLeftRadius: "3px",
          },
          "&:last-of-type": {
            borderTopRightRadius: "3px",
          },
        },
        root: {
          fontSize: "0.875rem",
          lineHeight: "1.75rem",
          borderBottomColor: "#ECECEC",
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: "currentColor",
        },
        root: {
          "&.Mui-active": {
            color: "#5A6474",
            fontWeight: 500,
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        head: {
          // borderLeft: 'none',
          // borderRight: 'none',
        },
        root: {
          // borderLeft: '1px solid #ECECEC',
          // borderRight: '1px solid #ECECEC',
          "&.MuiTableRow-hover": {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },

          "&.Mui-selected": {
            backgroundColor: primaryActiveLight,
            "&:hover": {
              backgroundColor: primaryActiveLight,
            },
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-indeterminate": {
            color: primaryActive,
          },
          "&.Mui-checked": {
            color: primaryActive,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "1px",
          height: "48px",
          fontWeight: 700,
          borderRadius: "8px",
          fontSize: "1rem",
          lineHeight: "1px",
          textTransform: "none",
          boxShadow: "none",
          // letterSpacing: '0.64px',
          padding: "0 1.625rem",
          color: '#0A4DFF'
        },

        outlinedError: {
          borderColor: '#BE0203',
          color: '#BE0203',

          "&:hover": {
            borderColor: '#BE0203',
            color: '#BE0203',
            background: '#FFE6E6',
          },
        },

        containedError: {
          background: '#BE0203',
          boxShadow: '0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset',
          color: white,

          "&:hover": {
            background: "#9F0405",
            boxShadow: "none",
          },
        },

        outlinedPrimary: {
          borderColor: '#000',
          color: '#000',

          "&:hover": {
            borderColor: '#000',
            color: '#000',
            background: '#F4F5F7',
          },
        },

        containedPrimary: {
          background: '#000',
          boxShadow: '0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset',
          color: white,

          "&:hover": {
            background: "#000",
            boxShadow: "none",
          },
        },

        containedSecondary: {
          background: '#000',
          boxShadow: '0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset',
          color: white,

          "&:hover": {
            background: "#000",
            boxShadow: "none",
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #ECECEC",
        },
        action: {
          margin: 0,
        },
        title: {
          color: secondary,
          fontSize: "1.125rem",
          fontWeight: 700,
          lineHeight: "1.75rem",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: white,
          boxShadow: "none",
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "0.0625rem",
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },

        primary: {
          color: primary,
          fontSize: "0.9375rem",
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          background: grey,
          borderRadius: "8px",
          width: "2.625rem",
          height: "2.625rem",
        },

        colorPrimary: {
          background: '#000',
          "&:hover": {
            background: '#000',
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        track: {
          boxShadow: '0px 2px 2px -1px rgba(74, 74, 104, 0.10) inset',
          borderRadius: '24px',
          opacity: 1,
          background: '#ECF1F4',
        },
        input: {
          width: '100%',
          left: 0
        },
        thumb: {
          width: 12,
          height: 12,
          // boxShadow: '0px 5.5px 5px -3px rgba(14, 14, 44, 0.20), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset',
        },
        switchBase: {
          // width: '100%',
          // height: '100%',
          // boxShadow: 'none',
          padding: '0',
          transform: 'translateX(4px) translateY(3.5px)',

          '&.Mui-checked': {
            transform: 'translateX(20px) translateY(3.5px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              background: '#000',
              opacity: 1,
            },
          }
        },
        root: {
          padding: 0,
          width: '36px',
          height: '20px'
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          columnGap: "0.75rem",

          "&:before": {
            content: '""',
            width: "0.25rem",
            height: "80%",
            position: "absolute",
            transform: "translateY(-50%)",
            background: "transparent",
            left: 0,
            borderRadius: "0 100px 100px 0",
            top: "50%",
            transition: "all ease-in-out .3s",
          },

          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: primaryActive,

            "&:before": {
              background: primaryActive,
            },

            "& .MuiListItemIcon-root": {
              color: primaryActive,
            },

            "& .MuiListItemText-primary": {
              color: primaryActive,
              fontWeight: 700,
            },

            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        gutters: {
          padding: "0.75rem 1.5rem",
        },
      },
    },
  },
});

export default theme;
