import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormLabel,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Filter } from "../../icons";
import { ComponentWrapper } from "../../shared/ComponentWrapper";
import { SearchBar } from "../../shared/SearchBar";
import EnhancedTable from "../../shared/Table";
import { fetchFormDetail, fetchForms, updateForm } from "../../services";
import { Loader, Modal } from "../../shared/Components";
import { useNavigate } from "react-router-dom";
import { FORM_BUILDER_ADD } from "../../routes/RouteConstants";
import { Formik } from "formik";
import { FORM_BUILDER_FIELDS_NAME } from "./constant";
import { FormBuilderSchema } from "../../validationSchemas";
import toast from "react-hot-toast";
import { InputBox } from "../../shared/Components/TextField";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Form name",
  },
  {
    id: "tenant",
    numeric: false,
    disablePadding: true,
    label: "Tenant",
  },
  {
    id: "site",
    numeric: true,
    disablePadding: false,
    label: "Site",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

interface Data {
  name: string;
  tenant: string;
  site: string;
  status: string;
  id: string;
}

function createData(
  name: string,
  tenant: string,
  site: string,
  status: string,
  id: string
): Data {
  return {
    name,
    tenant,
    site,
    status,
    id,
  };
}

const Forms = () => {
  const navigate = useNavigate();
  const selectForm = useRef<Record<string, any>>({});

  const [formDetails, setFormDetails] = useState<Record<string, any>>({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchForms()
      .then((res) => {
        const data = res.data.data.list.map((item: Record<string, any>) =>
          createData(item.name, item.tenantId, item.site, item.status, item._id)
        );
        setForms(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refresh]);

  const actions = {
    Edit: (row: Record<string, any>) => {
      selectForm.current = row;
      setOpen(true);
    },
  };

  const onRowClick = (row: Record<string, any>) => {
    navigate(FORM_BUILDER_ADD, { state: { formID: row.id } });
  };

  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs, rj) => {
      updateForm(selectForm.current.id, values)
        .then(() => {
          toast.success("Form Updated Successfully");
          setOpen(false);
          setRefresh((p) => !p);
        })
        .finally(() => {
          rs("");
        });
    });
  };

  useEffect(() => {
    if (open && selectForm.current?.id) {
      setLoadingDetails(true);
      fetchFormDetail(selectForm.current?.id)
        .then((res) => {
          setFormDetails({
            name: res.data?.data?.name,
            site: res.data?.data?.site || "",
          });
        })
        .finally(() => {
          setLoadingDetails(false);
        });
    }
  }, [selectForm.current?.id, open]);

  return (
    <>
      <ComponentWrapper>
        <Card>
          <CardHeader
            action={
              <Box gap={1} display="flex">
                <Button
                  variant="contained"
                  onClick={() => navigate(FORM_BUILDER_ADD)}
                >
                  Create
                </Button>
              </Box>
            }
            title="Widget Forms"
          />
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <EnhancedTable
                actions={actions}
                onRowClick={(row) => onRowClick(row)}
                columns={columns}
                data={forms}
              />
            )}
          </CardContent>
        </Card>
      </ComponentWrapper>

      {/* <ConfirmationModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() =>
          new Promise((rs) => {
            deleteTenant(selectedRow.current.id).then((res) => {
              rs("");
              setDeleteModal(false);
              setRefresh((p) => !p);
            });
          })
        }
      /> */}

      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        title={"Update Form Details"}
      >
        {loadingDetails ? (
          <Loader />
        ) : (
          <Formik
            initialValues={formDetails}
            enableReinitialize
            validationSchema={FormBuilderSchema}
            validateOnChange
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              values,
              isValid,
              dirty,
            }) => (
              <form style={{ height: "100%" }} onSubmit={handleSubmit}>
                <Box
                  sx={{ height: "100%" }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Box className="form-group">
                      <FormLabel>Company Name</FormLabel>
                      <InputBox
                        autoComplete="off"
                        onChange={handleChange}
                        name={FORM_BUILDER_FIELDS_NAME.name}
                        defaultValue={values.name}
                        required
                        fullWidth
                        placeholder="Enter Form Name"
                        variant="outlined"
                      />
                    </Box>

                    <Box className="form-group">
                      <FormLabel>Company website</FormLabel>
                      <InputBox
                        onChange={handleChange}
                        name={FORM_BUILDER_FIELDS_NAME.site}
                        defaultValue={values.site}
                        fullWidth
                        placeholder="Enter website"
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      gap={2}
                      mt={2}
                      display="flex"
                      sx={{
                        borderTop: "1px solid #ECECEC",
                        p: "1rem 2rem",
                      }}
                    >
                      <Button
                        onClick={() => setOpen(false)}
                        sx={{ flex: 1 }}
                        variant="outlined"
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        sx={{ flex: 1, color: "white" }}
                        variant="contained"
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting ? <Loader size={25} /> : "Update"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default Forms;
