import React from "react";
import { ComponentWrapper } from "../../shared/ComponentWrapper";
import { Card, CardContent, CardHeader } from "@mui/material";

const Dashboard = () => {
  return (
    <ComponentWrapper>
      <Card>
        <CardHeader title="Dashboard" />
        <CardContent>
          {/* <EnhancedTable>
            <Box gap={0.5} display="flex" alignItems="center">
              <Typography variant="h3" sx={{ p: 0 }}>
                Sort By -{" "}
              </Typography>
              <BasicMenu
                items={[
                  { id: 0, text: "All Users" },
                  { id: 1, text: "Active Users" },
                  { id: 2, text: "Inactive Users" },
                  { id: 3, text: "Invited Users" },
                ]}
              />
            </Box>
          </EnhancedTable> */}
        </CardContent>
      </Card>
    </ComponentWrapper>
  );
};

export default Dashboard;
