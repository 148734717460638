import React from "react";
import { ColorPicker as MUIColorPicker, ColorPickerProps } from "mui-color";

interface Props extends ColorPickerProps {
  value: string;
  onChange: any;
}

export const ColorPicker = ({ value, onChange, ...props }: Props) => {
  return <MUIColorPicker value={value} onChange={onChange} {...props} />;
};

export default ColorPicker;
