import React from "react";
import { Box, Button, FormLabel } from "@mui/material";
import { Formik } from "formik";

import { Loader, UploadButton } from "../../shared/Components";
import { TENANT_FIELD_NAMES } from "./constant";
import { updateTenant, uploadFile } from "../../services";
import { createTenantPayload, getObjectDifferences } from "./TenantUtils";

const Documents = ({ initialValues = {}, onSuccess }: any) => {
  const [reset, setReset] = React.useState(false);

  const uploadDocument = (
    e: React.ChangeEvent<HTMLInputElement>,
    onSuccess: (res: Record<string, any>) => void
  ) => {
    if (e.currentTarget.files?.[0]) {
      const formData: any = new FormData();
      formData.append("file", e.currentTarget.files?.[0]);
      formData.append("isPrivate", true);
      uploadFile(formData)
        .then(onSuccess)
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onReset = (setFieldValue: any) => {
    setFieldValue(TENANT_FIELD_NAMES.document_pan, null);
    setFieldValue(TENANT_FIELD_NAMES.document_gst, null);
    setReset((p) => !p);
  };

  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      const newValues = getObjectDifferences(values, initialValues);
      const payload = createTenantPayload(newValues);
      updateTenant(initialValues._id, payload).then((res) => {
        rs("");
        onSuccess({ ...values });
      });
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, handleReset, handleSubmit, isSubmitting }) => (
        <form style={{ height: "100%" }} onSubmit={handleSubmit}>
          <Box
            sx={{ height: "100%" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Box>
              <Box className="form-group">
                <FormLabel>PAN Card</FormLabel>
                <UploadButton
                  allowedExtensions={["jpg", "jpeg", "png", "webp", "pdf"]}
                  name={TENANT_FIELD_NAMES.document_pan}
                  reset={reset}
                  onChange={(e) =>
                    uploadDocument(e, (res: Record<string, any>) => {
                      setFieldValue(
                        TENANT_FIELD_NAMES.document_pan,
                        res.data.data._id
                      );
                    })
                  }
                />
              </Box>

              <Box className="form-group">
                <FormLabel>GST Certificate</FormLabel>
                <UploadButton
                  allowedExtensions={["jpg", "jpeg", "png", "webp", "pdf"]}
                  reset={reset}
                  name={TENANT_FIELD_NAMES.document_gst}
                  onChange={(e) =>
                    uploadDocument(e, (res: Record<string, any>) => {
                      setFieldValue(
                        TENANT_FIELD_NAMES.document_gst,
                        res.data.data._id
                      );
                    })
                  }
                />
              </Box>
              <Box className="form-group">
                <FormLabel>Registration Certificate</FormLabel>
                <UploadButton
                  allowedExtensions={["jpg", "jpeg", "png", "webp", "pdf"]}
                  reset={reset}
                  name={TENANT_FIELD_NAMES.document_rc}
                  onChange={(e) =>
                    uploadDocument(e, (res: Record<string, any>) => {
                      setFieldValue(
                        TENANT_FIELD_NAMES.document_rc,
                        res.data.data._id
                      );
                    })
                  }
                />
              </Box>
            </Box>
            <Box>
              <Box
                gap={2}
                display="flex"
                sx={{
                  borderTop: "1px solid #ECECEC",
                  p: "1rem 2rem",
                }}
              >
                <Button
                  onClick={() => {
                    onReset(setFieldValue);
                    handleReset();
                  }}
                  sx={{ flex: 1 }}
                  variant="outlined"
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  sx={{ flex: 1 }}
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Loader /> : "Save"}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Documents;
