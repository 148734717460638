import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ComponentWrapper } from "../../shared/ComponentWrapper";
import { SearchBar } from "../../shared/SearchBar";
import { Filter } from "../../icons";
import AddIcon from "@mui/icons-material/Add";
import { TemporaryDrawer } from "../../shared/Drawer";
import { useState } from "react";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      flexGrow={1}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabs_Arr = [
  {
    id: 0,
    label: "Leads Manager",
  },
  {
    id: 1,
    label: "Lead Nurturing Module",
  },
  {
    id: 2,
    label: "Application Manager",
  },
  {
    id: 3,
    label: "Campaign Manager",
  },
  {
    id: 4,
    label: "Communication Manager",
  },
  {
    id: 5,
    label: "Query Manager",
  },
  {
    id: 6,
    label: "Raw Data",
  },
];

const RolesAndPermission = () => {
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <ComponentWrapper>
        <Card>
          <CardHeader title="Roles & Permissions" />
          <CardContent>
            {/* <EnhancedTable>
              <Box gap={0.5} display="flex" alignItems="center">
                <Typography variant="h3" sx={{ p: 0 }}>
                  Sort By -{" "}
                </Typography>
                <BasicMenu
                  items={[
                    { id: 0, text: "All Users" },
                    { id: 1, text: "Active Users" },
                    { id: 2, text: "Inactive Users" },
                    { id: 3, text: "Invited Users" },
                  ]}
                />
              </Box>
            </EnhancedTable> */}
          </CardContent>
        </Card>
      </ComponentWrapper>
      <TemporaryDrawer
        action
        heading="Create Role"
        onClose={() => setShow(false)}
        open={show}
      >
        <Box display="flex" flexDirection="column" rowGap={3}>
          <Box>
            <Box
              sx={{ mb: "0.62rem" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2">1/3</Typography>
              <Typography variant="body2">First Step</Typography>
            </Box>
            <LinearProgress variant="determinate" value={66} />
          </Box>

          <Box>
            <FormLabel>Role Name</FormLabel>
            <TextField
              fullWidth
              placeholder="Enter Role Name"
              variant="outlined"
            />
          </Box>

          <Box display="flex" gap={2}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              orientation="vertical"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                width: "200px",
                borderRight: "1px solid #ECECEC",
                "& .MuiTabScrollButton-root.Mui-disabled": {
                  opacity: 0.2,
                },
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontSize: "0.875rem",
                  color: "#5A6474",
                  padding: "0 1rem",
                  minHeight: "56px",
                  alignItems: "flex-start",

                  "&.Mui-selected": {
                    color: "#002F5F",
                    fontWeight: 700,
                    background: "#E5EAEF",
                    borderRadius: "0.5rem 0 0 0.5rem",
                  },
                },

                "& .MuiTabs-indicator": {
                  backgroundColor: "#002F5F",
                },
              }}
            >
              {tabs_Arr.map((tab) => (
                <Tab key={tab.id} {...a11yProps(tab.id)} label={tab.label} />
              ))}
            </Tabs>

            <TabPanel value={value} index={0}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  labelPlacement="start"
                  label="Module 1"
                />
                <FormControlLabel
                  control={<Switch />}
                  labelPlacement="start"
                  label="Module 2"
                />
                <FormControlLabel
                  control={<Switch />}
                  labelPlacement="start"
                  label="Module 3"
                />
              </FormGroup>
            </TabPanel>
            <TabPanel value={value} index={1}>
              fsfs
            </TabPanel>
            <TabPanel value={value} index={2}>
              fsfs
            </TabPanel>
            <TabPanel value={value} index={3}>
              fsfs
            </TabPanel>
            <TabPanel value={value} index={4}>
              fsfs
            </TabPanel>
            <TabPanel value={value} index={5}>
              fsfs
            </TabPanel>
            <TabPanel value={value} index={6}>
              fsfs
            </TabPanel>
          </Box>

          {/* <EnhancedTable /> */}
        </Box>
      </TemporaryDrawer>
    </>
  );
};

export default RolesAndPermission;
