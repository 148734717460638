import { Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";

import { ComponentWrapper } from "../../shared/ComponentWrapper";
import EnhancedTable from "../../shared/Table";
import { TemporaryDrawer } from "../../shared/Drawer";
import CreateTenant from "../CreateTenant";
import { fetchTenantDetails, fetchTenants, updateTenant } from "../../services";
import { ConfirmationModal, Loader } from "../../shared/Components";

const GET_STATUS = { ACTIVE: "INACTIVE", INACTIVE: "ACTIVE" };

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Company name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
];

interface Data {
  name: string;
  email: string;
  createdAt: string;
  id: string;
}

function createData(
  name: string,
  email: string,
  createdAt: string,
  id: string
): Data {
  return {
    name,
    email,
    createdAt: new Date(createdAt).toDateString(),
    id,
  };
}

const Tenants = () => {
  const [show, setShow] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const selectedRow = useRef<Record<string, any>>({});

  useEffect(() => {
    setLoading(true);
    fetchTenants()
      .then((res) => {
        const data = res.data.data.list.map((item: Record<string, any>) =>
          createData(item.name, item.email, item.createdAt, item._id)
        );
        setTenants(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refresh]);

  const actions = {
    Edit: (row: Record<string, any>) => {
      selectedRow.current = row;
      setShow(true);
    },
    "Disable/Enable": (row: Record<string, any>) => {
      selectedRow.current = row;
      setDeleteModal(true);
    },
  };

  return (
    <>
      <ComponentWrapper>
        <Card>
          <CardHeader
            action={
              <Box gap={1} display="flex">
                <Button
                  variant="contained"
                  onClick={() => {
                    selectedRow.current = {};
                    setShow(true);
                  }}
                >
                  Create
                </Button>
              </Box>
            }
            title={`Our Tenants(${loading ? "..." : tenants.length})`}
          />
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <EnhancedTable
                actions={actions}
                columns={columns}
                data={tenants}
              />
            )}
          </CardContent>
        </Card>
      </ComponentWrapper>

      <TemporaryDrawer
        heading="Create Tenant"
        onClose={() => setShow(false)}
        open={show}
        size={"md"}
      >
        <CreateTenant
          tenantId={selectedRow.current.id}
          onSuccess={() => {
            setRefresh((p) => !p);
            selectedRow.current = {};
          }}
        />
      </TemporaryDrawer>

      <ConfirmationModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() =>
          new Promise((rs) => {
            fetchTenantDetails(selectedRow.current.id).then((res) => {
              updateTenant(selectedRow.current.id, {
                status:
                  GET_STATUS[res.data.data.status as keyof typeof GET_STATUS],
              }).then((res) => {
                rs("");
                toast.success("Changed Status Successfully");
                selectedRow.current = {};
                setDeleteModal(false);
                setRefresh((p) => !p);
              });
            });
          })
        }
      />
    </>
  );
};

export default Tenants;
