const vars = {
  primaryFont: "'DM Sans', sans-serif",
  primary: "#5A6474",
  primaryActive: "#000",
  primaryActiveLight: "#EEF3FF",
  secondary: "#121212",
  grey: "#F4F5F7",
  lightGrey: "#B8B8B8",
  white: "#fff",
};

export default vars;
