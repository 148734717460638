import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Form, Formik, getIn } from "formik";
import { TENANT_FIELD_NAMES } from "./constant";
import { updateTenant } from "../../services";
import { createTenantPayload, getObjectDifferences } from "./TenantUtils";
import { Loader } from "../../shared/Components";
import { PricingSchema } from "../../validationSchemas";
import { InputBox } from "../../shared/Components/TextField";

const Pricing = ({ initialValues, onSuccess }: any) => {
  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      const newValues = getObjectDifferences(values, initialValues);
      const payload = createTenantPayload(newValues);
      updateTenant(initialValues._id, payload).then((res) => {
        rs("");
        onSuccess({ ...values });
      });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validateOnChange
      validationSchema={PricingSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
      }) => (
        <Form style={{ height: "100%" }} onSubmit={handleSubmit}>
          <Box
            sx={{ height: "100%" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Box>
              <Box className="form-group">
                <FormLabel>One Time Onboarding fee</FormLabel>
                <InputBox
                  onChange={handleChange}
                  fullWidth
                  value={getIn(values, TENANT_FIELD_NAMES.oneTime) || ""}
                  name={TENANT_FIELD_NAMES.oneTime}
                  placeholder="Enter enter amount"
                  variant="outlined"
                />
              </Box>

              <Box className="form-group">
                <FormLabel>Per month per branch</FormLabel>
                <InputBox
                  onChange={handleChange}
                  name={TENANT_FIELD_NAMES.perMonth}
                  value={getIn(values, TENANT_FIELD_NAMES.perMonth) || ""}
                  fullWidth
                  placeholder="Enter enter amount"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box>
              <Box
                gap={2}
                display="flex"
                sx={{
                  borderTop: "1px solid #ECECEC",
                  p: "1rem 2rem",
                }}
              >
                <Button
                  onClick={() => {
                    handleReset();
                  }}
                  sx={{ flex: 1 }}
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  Reset
                </Button>
                <Button
                  disabled={!(isValid && dirty) || isSubmitting}
                  type="submit"
                  sx={{ flex: 1 }}
                  variant="contained"
                >
                  {isSubmitting ? <Loader /> : "Save"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Pricing;
