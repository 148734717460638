import React, { useContext, useRef } from "react";
import type { FC } from "react";

import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { Down, NavIcon } from "../../icons";
import LOGO from "../../images/logo.svg";
import { AppContext } from "../../contexts";
import { ActionType } from "../../reducers";

interface IHeader {
  setOpen: Function;
  open: boolean;
}

const classes = {
  root: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "4.5rem",
    boxShadow: "0px 2px 10px 0px rgba(27, 46, 94, 0.04)",
    background: "#FFF",
  },
};

export const Header: FC<IHeader> = ({ setOpen, open }) => {
  const { dispatch } = useContext<any>(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cacheWidth = useRef<undefined | number>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    cacheWidth.current = event.currentTarget.getBoundingClientRect().width;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    dispatch({
      type: ActionType.LOGOUT,
      payload: "",
    });
  };

  return (
    <Box pl={open ? 0 : 3} pr={3} sx={classes.root}>
      <Box height={1} display="flex" alignItems="center">
        {open ? (
          <IconButton
            sx={{
              borderRadius: 0,
              width: "auto",
              px: "1.5rem",
              height: "100%",
            }}
            onClick={() => setOpen((prev: boolean) => !prev)}
          >
            <NavIcon />
          </IconButton>
        ) : (
          <img src={LOGO} alt="logo" />
        )}
      </Box>

      <Box display="flex" gap={2.5}>
        <Box borderLeft="1px solid #E1E3E6" pl={2.5}>
          <IconButton
            onClick={handleClick}
            sx={{
              width: "auto",
              height: "auto",
              padding: 0,
              gap: 1,
              backgroundColor: "transparent",

              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Avatar
              sx={{
                width: "2.625rem",
                height: "2.625rem",
              }}
              alt="Alice Garza"
              src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1160&q=80"
            />
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#121212",
              }}
            >
              Alice Garza
            </Typography>
            <Down />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              marginTop: "1rem",
            }}
            slotProps={{
              paper: {
                sx: {
                  width: cacheWidth.current,
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
          >
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};
