import React, { useContext, useLayoutEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { AppContext, initialState } from "./contexts";
import { ActionType, UserReducer } from "./reducers";
import { UserToken } from "./services";
import { getUserMe } from "./services/AuthServices";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Toaster, ToastBar } from "react-hot-toast";

function Main() {
  const { dispatch } = useContext<any>(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // auto login user if token exists
    if (UserToken()) {
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: true,
      });
      getUserMe()
        .then((res) => {
          dispatch({
            type: ActionType.GET_USER_SUCCESS,
            payload: res.data.data,
          });
          location && navigate(location.pathname);
        })
        .catch(() => {
          dispatch({
            type: ActionType.SET_LOADING_FLAG,
            payload: false,
          });
        });
    } else {
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: false,
      });
    }
  }, []);

  const routing = useRoutes(routes);

  return routing;
}

function App() {
  const [state, dispatch] = useReducer(UserReducer, initialState.state);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <Main />
        {ReactDOM.createPortal(
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 3000,
              style: {
                fontSize: "1rem",
                fontWeight: 500,
                padding: "0.8rem 1rem",
              },
            }}
          >
            {(t) => (
              <ToastBar
                toast={t}
                style={{
                  ...t.style,
                  opacity: 1,
                  zIndex: 2,
                }}
              />
            )}
          </Toaster>,
          document.body
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
