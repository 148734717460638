import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { Form, Formik } from "formik";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LOGO from "../../images/logo.svg";
import { logIn } from "../../services";
import { ActionType } from "../../reducers";
import { AppContext } from "../../contexts";
import { DASHBOARD } from "../../routes/RouteConstants";
import { Loader } from "../../shared/Components";
import { InputBox } from "../../shared/Components/TextField";
import { LoginSchema } from "../../validationSchemas";
import { LOGIN_FIELDS_NAME } from "./constant";

export const inputStyle = {
  "& .MuiOutlinedInput-input": {
    height: "auto",
    padding: "14.5px 14px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&.Mui-focused": {
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#282730",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        // borderWidth: '1px',
        borderColor: "#282730",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#282730",
      },
    },
  },
};

const classes = {
  root: {
    background: "#fff",
  },

  wrap: {
    width: "450px",
    maxWidth: "450px",
  },
};

const Login = () => {
  const { dispatch } = useContext<any>(AppContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      logIn(values)
        .then((res) => {
          const data = res.data.data;
          localStorage.setItem("token", data.token);

          dispatch({
            type: ActionType.GET_USER_SUCCESS,
            payload: data.user,
          });
          navigate(DASHBOARD);
        })
        .catch(() => {
          toast.error("Incorrect Email or Password");
        })
        .finally(() => {
          rs("");
        });
    });
  };

  return (
    <Box sx={classes.root} p={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              height: "calc(100vh - 32px)",
            }}
            display="flex"
            justifyContent={"center"}
            flexDirection="column"
            alignItems="center"
            px={10}
          >
            <Grid item>
              <Box sx={classes.wrap}>
                <img src={LOGO} alt="logo" />
              </Box>
              <Box sx={classes.wrap} mt={5}>
                <Typography variant="h2">Welcome back</Typography>
                <Typography variant="body2">
                  Enter your credentials to access your account
                </Typography>
              </Box>
              <Formik
                initialValues={{}}
                enableReinitialize
                validateOnMount
                validateOnChange
                validationSchema={LoginSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, handleChange, isSubmitting, isValid }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box sx={classes.wrap} mt={5}>
                      <Box className="form-group">
                        <FormLabel>Email Address</FormLabel>
                        <InputBox
                          name={LOGIN_FIELDS_NAME.email}
                          sx={inputStyle}
                          onChange={handleChange}
                          fullWidth
                          placeholder="Enter Email Address"
                          variant="outlined"
                        />
                      </Box>

                      <Box className="form-group">
                        <FormLabel>Password</FormLabel>
                        <InputBox
                          sx={inputStyle}
                          name={LOGIN_FIELDS_NAME.password}
                          onChange={handleChange}
                          InputProps={{
                            ...(showPassword ? { type: "text" } : {}),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword((p) => !p)}
                                  disableRipple
                                  sx={{
                                    width: "auto",
                                    height: "auto",
                                    background: "transparent",
                                    padding: 0,
                                  }}
                                >
                                  <VisibilityOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          type="password"
                          placeholder="Enter Min 8 chars"
                          variant="outlined"
                        />
                      </Box>

                      <Box className="form-group">
                        <Button
                          variant="contained"
                          disabled={!isValid || isSubmitting}
                          fullWidth
                          type="submit"
                        >
                          {isSubmitting ? <Loader size={25} /> : "Log In"}
                        </Button>
                      </Box>

                      <Box className="form-group">
                        <Typography
                          align="center"
                          variant="subtitle2"
                          sx={{ fontWeight: 500, color: "#726E89" }}
                        >
                          {new Date().getFullYear()} SuperCRM, All right
                          Reserved
                        </Typography>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
