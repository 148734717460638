export const createTenantPayload = (source: Record<string, any>) => {
  const {
    document_gst,
    document_pan,
    document_rc,
    documents,

    // Keys to exclude from object
    defaultDomains,
    accountType,
    domains,
    currency,
    ...rest
  } = source;

  let target: Record<string, any> = {
    // modules: [], // Only for module tab
    ...rest,
  };

  if (document_pan) {
    if (
      target?.documents?.find(
        (item: Record<string, any>) => item.documentType === "PAN"
      )
    ) {
      target.documents?.map((item: any) => {
        if (item.documentType === "PAN") {
          return {
            documentType: "PAN",
            file: document_pan,
          };
        }
        return item;
      });
    } else {
      target.documents = target.documents?.length ? target.documents : [];
      target.documents?.push({
        documentType: "PAN",
        file: document_pan,
      });
    }
  }

  if (document_gst) {
    if (
      target.documents.find(
        (item: Record<string, any>) => item.documentType === "GC"
      )
    ) {
      target.documents.map((item: any) => {
        if (item.documentType === "GC") {
          return {
            documentType: "GC",
            file: document_gst,
          };
        }
        return item;
      });
    } else {
      target.documents.push({
        documentType: "GC",
        file: document_gst,
      });
    }
  }

  if (document_rc) {
    if (
      target.documents.find(
        (item: Record<string, any>) => item.documentType === "RC"
      )
    ) {
      target.documents.map((item: any) => {
        if (item.documentType === "RC") {
          return {
            documentType: "RC",
            file: document_rc,
          };
        }
        return item;
      });
    } else {
      target.documents.push({
        documentType: "RC",
        file: document_rc,
      });
    }
  }
  // Only for module tab.
  // for (const key in source) {
  //   if (key.includes("module")) {
  //     target.modules.push(source[key]);
  //   }
  // }

  return target;
};

export const isObject = (item: any): boolean =>
  item && typeof item === "object" && !Array.isArray(item);

export const compareArrays = (arr1: any[], arr2: any[]): any[] => {
  const differences: any[] = [];

  arr1.forEach((item1, index) => {
    const item2 = arr2[index];
    const itemDifferences = getObjectDifferences(item1, item2);
    if (Object.keys(itemDifferences).length > 0) {
      differences.push(itemDifferences);
    } else {
      differences.push(item1);
    }
  });

  return differences;
};

interface AnyObject {
  [key: string]: any;
}
export const getObjectDifferences = (
  obj1: AnyObject,
  obj2: AnyObject
): AnyObject => {
  const differences: AnyObject = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key)) {
        differences[key] = obj1[key];
      } else if (isObject(obj1[key]) && isObject(obj2[key])) {
        const nestedDifferences = getObjectDifferences(obj1[key], obj2[key]);
        if (Object.keys(nestedDifferences).length > 0) {
          differences[key] = nestedDifferences;
        }
      } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        const arrayDifferences = compareArrays(obj1[key], obj2[key]);
        if (arrayDifferences.length > 0) {
          differences[key] = arrayDifferences;
        }
      } else if (obj1[key] !== obj2[key]) {
        differences[key] = obj1[key];
      }
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      differences[key] = obj2[key];
    }
  }

  return differences;
};
