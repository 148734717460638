import axios from "axios";
import { BASE_URL } from "../global/constants";
import { UserToken } from "./AuthServices";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${UserToken()}`;
  return config;
});

instance.interceptors.response.use(
  (successRes) => successRes,
  ({ response }) => {
    if (response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return Promise.reject(response);
  }
);

export const httpService = instance;
