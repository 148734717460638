import { createContext } from "react";

export const initialState = {
  state: {
    page_loading: true,
  },
  dispatch: () => {},
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);
