import { httpService } from "./AxiosInstance";

export const fetchTenants = () => {
  return httpService.get("/tenant");
};

export const fetchTenantDetails = (id: string) => {
  return httpService.get(`/tenant/${id}`);
};

export const createTenant = (payload: Record<string, any>) => {
  return httpService.post("/tenant", payload);
};

export const updateTenant = (tenantId: string, payload: Record<string, any>) => {
  return httpService.patch(`/tenant/${tenantId}`, payload);
};
