import { Dashboard, Lead, Settings, User, Widget } from "../../icons";
import {
  TENANTS,
  DASHBOARD,
  FORM_BUILDER,
  ROLES_AND_PERMISSION,
} from "../../routes/RouteConstants";

export const MenuArr = [
  {
    id: "0",
    heading: "Menu",
    data: [
      {
        id: "0.1",
        icon: Dashboard,
        text: "Dashboard",
        link: DASHBOARD,
      },
      {
        id: "0.2",
        icon: Lead,
        text: "Tenants",
        link: TENANTS,
      },
      {
        id: "0.4",
        icon: User,
        text: "User & Permissions",
        link: ROLES_AND_PERMISSION,
      },
      {
        id: "0.3",
        icon: Widget,
        text: "Form Builder",
        link: FORM_BUILDER,
      },
    ],
  }
];
