import { Button, SvgIcon } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";

interface IUploadButton {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  required?: boolean;
  reset?: boolean;
  allowedExtensions?: string[];
}

export const UploadButton = ({
  allowedExtensions,
  placeholder = "Upload File",
  onChange,
  name,
  required,
  reset,
}: IUploadButton) => {
  const [filename, setFilename] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]?.name || "";

    if (file) {
      if (allowedExtensions?.length) {
        const fileExt = file.split(".").at(-1);

        if (fileExt && allowedExtensions?.includes(fileExt.toLowerCase())) {
          setFilename(file);
          onChange?.(e);
        } else {
          toast.error("Error: Invalid file type");
        }
      } else {
        setFilename(file);
        onChange?.(e);
      }
    }
  };

  React.useEffect(() => {
    setFilename("");
  }, [reset]);
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          height: "40px",
          padding: "0 1rem",
        }}
        startIcon={
          <SvgIcon>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
          </SvgIcon>
        }
        component="label"
      >
        <div>{placeholder}</div>
        <input
          type="file"
          name={name}
          required={!!required}
          style={{ width: 1, height: 1 }}
          onChange={handleChange}
        />
      </Button>{" "}
      <span style={{ fontWeight: "bold" }}>{filename}</span>
    </>
  );
};

export default UploadButton;
