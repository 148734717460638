import React, { useState } from "react";
import { ComponentWrapper } from "../../shared/ComponentWrapper";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import {
  Editor,
  FormErrorFallback,
  Loader,
  Modal,
} from "../../shared/Components";
import {
  DEFAULT_FORM_SCHEMA,
  DEFAULT_UI_SCHEMA,
} from "../../shared/Components/Editor/configs";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { isJsonString } from "../../shared/utils";
import { ErrorBoundary } from "react-error-boundary";
import {
  createForm,
  fetchFormDetail,
  fetchTenants,
  updateForm,
} from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { FORM_BUILDER } from "../../routes/RouteConstants";
import { Formik } from "formik";
import { FormBuilderSchema } from "../../validationSchemas";
import toast from "react-hot-toast";
import { FORM_BUILDER_FIELDS_NAME } from "./constant";
import { InputBox } from "../../shared/Components/TextField";

const editorContainerSyles = {
  border: "1px solid lightgrey",
  borderRadius: 2,
  overflow: "hidden",
};

const FormBuilder = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formID = state?.formID;

  const [tenants, setTenants] = useState<Record<string, any>[]>([]);
  const [tenant, setTenant] = useState<string | undefined>();

  const [loading, setLoading] = useState(!!formID);

  const [open, setOpen] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [formSchema, setFormSchema] = useState(DEFAULT_FORM_SCHEMA);
  const [invalidFormJson, setInvalidFormJson] = useState("");
  const [invalidUIJson, setInvalidUIJson] = useState("");
  const [uiSchema, setUiSchema] = React.useState(DEFAULT_UI_SCHEMA);

  const onFormSchemaChange = (value?: string) => {
    value = value || "";
    if (!isJsonString(value)) {
      setInvalidFormJson("Invalid Form Schema");
      return;
    }
    setFormSchema(value);
    invalidFormJson && setInvalidFormJson("");
  };

  const onUISchemaChange = (value?: string) => {
    value = value || "";
    if (!isJsonString(value)) {
      setInvalidUIJson("Invalid UI Schema");
      return;
    }
    setUiSchema(value);
    invalidUIJson && setInvalidUIJson("");
  };

  const onUpdate = () => {
    updateForm(formID, {
      formSchema: {
        schema: JSON.parse(formSchema),
        uiSchema: JSON.parse(uiSchema),
      },
    }).then((res) => {
      navigate(FORM_BUILDER);
    });
  };

  const onSubmit = (values: Record<string, any>) => {
    setPublishing(true);
    return new Promise((rs, rj) => {
      createForm({
        formSchema: {
          schema: JSON.parse(formSchema),
          uiSchema: JSON.parse(uiSchema),
        },
        tenantId: tenant,
        name: values.name,
        site: values.site,
      })
        .then((res) => {
          rs("");
          toast.success("Form Created Successfully");
          navigate(FORM_BUILDER);
        })
        .finally(() => {
          rs("");
          setPublishing(false);
        });
    });
  };

  React.useEffect(() => {
    fetchTenants().then((res) => {
      setTenants(res.data.data.list);
    });
  }, []);

  React.useEffect(() => {
    if (formID) {
      fetchFormDetail(formID).then((res) => {
        setUiSchema(
          (p) =>
            JSON.stringify(res.data?.data?.formSchema?.uiSchema, null, 1) || p
        );
        setFormSchema(
          (p) =>
            JSON.stringify(res.data?.data?.formSchema?.schema, null, 1) || p
        );
        setTenant((prev) => res.data?.data?.tenantId || prev);
        setLoading(false);
      });
    }
  }, [formID]);

  return (
    <>
      <ComponentWrapper>
        <Card>
          {loading ? (
            <Loader />
          ) : (
            <CardContent>
              <Grid container gap={3} boxSizing={"border-box"}>
                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item minWidth={"250px"}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Select Tenant
                      </InputLabel>
                      <Select
                        size="small"
                        disabled={!tenants.length}
                        value={tenant}
                        label="Select Tenant"
                        onChange={(e) => setTenant(e.target.value)}
                      >
                        {tenants.map((tenant) => (
                          <MenuItem value={tenant._id}>{tenant?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={() => {
                        if (formID) {
                          onUpdate();
                          return;
                        }
                        setOpen(true);
                      }}
                      disabled={!tenant || publishing}
                    >
                      {formID ? "Update" : "Save"}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container gap={2}>
                  <Grid
                    container
                    item
                    flexDirection={"column"}
                    gap={2}
                    boxSizing={"border-box"}
                    sx={{
                      width: "49%",
                    }}
                  >
                    <Grid item>
                      <Grid container justifyContent={"space-between"}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Form Schema
                        </Typography>
                        {invalidFormJson && (
                          <Typography variant="subtitle2" color={"red"}>
                            {invalidFormJson}
                          </Typography>
                        )}
                      </Grid>

                      <Box
                        sx={editorContainerSyles}
                        {...(invalidFormJson
                          ? { style: { borderColor: "red" } }
                          : {})}
                      >
                        <Editor
                          height={300}
                          defaultValue={formSchema}
                          onChange={onFormSchemaChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent={"space-between"}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          UI Schema
                        </Typography>
                        {invalidUIJson && (
                          <Typography variant="subtitle2" color={"red"}>
                            {invalidUIJson}
                          </Typography>
                        )}
                      </Grid>

                      <Box
                        sx={editorContainerSyles}
                        {...(invalidUIJson ? { borderColor: "red" } : {})}
                      >
                        <Editor
                          height={300}
                          defaultValue={uiSchema}
                          onChange={onUISchemaChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item width={"49%"} pt={2.8} boxSizing={"border-box"}>
                    <ErrorBoundary FallbackComponent={FormErrorFallback}>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          padding: "2rem",
                          borderRadius: 5,
                        }}
                      >
                        <Form
                          schema={JSON.parse(formSchema)}
                          uiSchema={JSON.parse(uiSchema)}
                          validator={validator}
                        />
                      </div>
                    </ErrorBoundary>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </ComponentWrapper>

      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        title={"Publish Form"}
      >
        <Formik
          initialValues={{}}
          validateOnChange
          validateOnMount
          validationSchema={FormBuilderSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, isSubmitting, isValid }) => (
            <form style={{ height: "100%" }} onSubmit={handleSubmit}>
              <Box
                sx={{ height: "100%" }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Box className="form-group">
                    <FormLabel>Company Name</FormLabel>
                    <InputBox
                      onChange={handleChange}
                      name={FORM_BUILDER_FIELDS_NAME.name}
                      required
                      fullWidth
                      placeholder="Enter Form Name"
                      variant="outlined"
                    />
                  </Box>

                  <Box className="form-group">
                    <FormLabel>Company website</FormLabel>
                    <InputBox
                      onChange={handleChange}
                      name={FORM_BUILDER_FIELDS_NAME.site}
                      fullWidth
                      placeholder="Enter website"
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    gap={2}
                    mt={2}
                    display="flex"
                    sx={{
                      borderTop: "1px solid #ECECEC",
                      p: "1rem 2rem",
                    }}
                  >
                    <Button
                      onClick={() => setOpen(false)}
                      sx={{ flex: 1 }}
                      variant="outlined"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="success"
                      sx={{ flex: 1, color: "white" }}
                      variant="contained"
                      disabled={!isValid || isSubmitting}
                    >
                      {isSubmitting ? <Loader size={25} /> : "Publish"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FormBuilder;
