import { Box, Button, FormLabel } from "@mui/material";
import { Form, Formik } from "formik";
import { TENANT_FIELD_NAMES } from "./constant";
import { createTenant, updateTenant } from "../../services";
import { Loader } from "../../shared/Components";
import { createTenantPayload, getObjectDifferences } from "./TenantUtils";
import { BasicDetailSchema } from "../../validationSchemas";
import { InputBox } from "../../shared/Components/TextField";

const BasicDetails = ({ initialValues = {}, onSuccess }: any) => {
  const onSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      const newValues = getObjectDifferences(values, initialValues);
      const payload = createTenantPayload(newValues);

      const apiHandler = initialValues._id
        ? updateTenant(initialValues._id, payload)
        : createTenant(payload);

      const toastMessage = !initialValues._id && "Tenant Created Successfully";

      apiHandler
        .then((res) => {
          onSuccess(
            {
              ...values,
              ...(res.data.data._id ? { _id: res.data.data._id } : {}),
            },
            toastMessage
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          rs("");
        });
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        validateOnChange
        validationSchema={BasicDetailSchema}
        onSubmit={onSubmit}
      >
        {({
          handleReset,
          handleSubmit,
          handleChange,
          values,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form style={{ height: "100%" }} onSubmit={handleSubmit}>
            <Box
              sx={{ height: "100%" }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box className="form-group">
                  <InputBox
                    onChange={handleChange}
                    value={values[TENANT_FIELD_NAMES.name] || ""}
                    name={TENANT_FIELD_NAMES.name}
                    required
                    fullWidth
                    label={"Company Name"}
                    placeholder="Enter Company Name"
                    variant="outlined"
                  />
                </Box>

                <Box className="form-group">
                  <InputBox
                    onChange={handleChange}
                    value={values[TENANT_FIELD_NAMES.website] || ""}
                    name={TENANT_FIELD_NAMES.website}
                    fullWidth
                    label={"Company website"}
                    placeholder="Enter Company website"
                    variant="outlined"
                  />
                </Box>

                <Box className="form-group">
                  <InputBox
                    onChange={handleChange}
                    fullWidth
                    value={values[TENANT_FIELD_NAMES.gstNumber] || ""}
                    name={TENANT_FIELD_NAMES.gstNumber}
                    placeholder="Enter GST Number"
                    label={"GST Number"}
                    variant="outlined"
                  />
                </Box>

                <Box className="form-group">
                  <InputBox
                    onChange={handleChange}
                    fullWidth
                    required
                    value={values[TENANT_FIELD_NAMES.email] || ""}
                    name={TENANT_FIELD_NAMES.email}
                    placeholder="Enter Primary email id"
                    label={"Primary email id"}
                    variant="outlined"
                  />
                </Box>

                <Box className="form-group">
                  <InputBox
                    onChange={handleChange}
                    fullWidth
                    required
                    value={values[TENANT_FIELD_NAMES.mobile] || ""}
                    name={TENANT_FIELD_NAMES.mobile}
                    label={"Primary mobile number"}
                    placeholder="Enter Primary mobile number"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  gap={2}
                  display="flex"
                  sx={{
                    borderTop: "1px solid #ECECEC",
                    p: "1rem 2rem",
                  }}
                >
                  <Button
                    onClick={handleReset}
                    sx={{ flex: 1 }}
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    sx={{ flex: 1 }}
                    variant="contained"
                    disabled={isSubmitting || !(isValid && dirty)}
                  >
                    {isSubmitting ? <Loader /> : "Save"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BasicDetails;
