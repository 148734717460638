import * as Yup from "yup";
import { TENANT_FIELD_NAMES } from "../pages/CreateTenant/constant";

export const BasicDetailSchema = Yup.object().shape({
  [TENANT_FIELD_NAMES.name]: Yup.string().required("Company name is required"),
  [TENANT_FIELD_NAMES.email]: Yup.string()
    .email("Invalid email address")
    .required("Primary email is required"),
  [TENANT_FIELD_NAMES.mobile]: Yup.string()
    .matches(/^[0-9]\d{9}$/, "Invalid Mobile number")
    .required("Contact number is required"),
  [TENANT_FIELD_NAMES.website]: Yup.string().url("Website is not valid"),
});
