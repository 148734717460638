import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { NavIcon } from "../../icons";
import LOGO from "../../images/logo.svg";
import vars from "../../theme/variables";
import { MenuArr } from "./constant";

const { white } = vars;

interface ISidebar {
  toggle: boolean;
  setToggle: Function;
}

export const Sidebar: FC<ISidebar> = ({ toggle, setToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = {
    root: {
      backgroundColor: white,
      flexShrink: 0,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },

    header: {
      flexShrink: 0,
      height: "4.5rem",
      padding: "0 0.75rem",
      display: "flex",
      alignItems: "center",
    },

    body: {
      overflow: "auto",
      flexGrow: 1,
    },

    footer: {
      flexShrink: 0,
      padding: "0.5rem 1.5rem",
    },
  };

  const handleRoute = (link: string) => {
    navigate(link);
  };

  return (
    <Box
      sx={{
        ...classes.root,
        width: toggle ? "16.875rem" : "4rem",
      }}
    >
      <Box sx={classes.header}>
        {!toggle ? (
          <IconButton onClick={() => setToggle((prev: boolean) => !prev)}>
            <NavIcon />
          </IconButton>
        ) : (
          <img src={LOGO} alt="logo" />
        )}
      </Box>
      <Box sx={classes.body}>
        {MenuArr?.map((menu) => (
          <Box key={menu?.id}>
            <List
              component="nav"
              subheader={
                toggle ? (
                  <Typography variant="h3">{menu?.heading}</Typography>
                ) : null
              }
            >
              {menu?.data?.map((item) => {
                // To add child menu items
                // if (item?.child) {
                //   return (
                //     <>
                //       <ListItemButton
                //         selected={open?.includes(item?.id)}
                //         onClick={() => {
                //           if (!toggle) {
                //             setToggle(true);
                //           }
                //           setOpen(() =>
                //             open?.includes(item?.id) ? "" : item?.id
                //           );
                //         }}
                //       >
                //         <ListItemIcon>
                //           <item.icon color="currentColor" />
                //         </ListItemIcon>
                //         {toggle && (
                //           <>
                //             <ListItemText primary={item?.text} />
                //             <Right
                //               color="currentColor"
                //               style={{
                //                 transform: open?.includes(item?.id)
                //                   ? "rotate(90deg)"
                //                   : "rotate(0deg)",
                //               }}
                //             />
                //           </>
                //         )}
                //       </ListItemButton>

                //       {/* {toggle && (
                //         <Box px={3}>
                //           <Collapse
                //             in={open?.includes(item?.id)}
                //             timeout="auto"
                //             unmountOnExit
                //           >
                //             <List component="div">
                //               {item?.child?.map((child) => (
                //                 <ListItemButton
                //                   selected={location?.pathname === child?.link}
                //                   key={child?.id}
                //                   onClick={() => handleRoute(child?.link)}
                //                   sx={{
                //                     pl: 4,
                //                     borderRadius: 2,
                //                     "&.Mui-selected": {
                //                       backgroundColor: primaryActiveLight,
                //                       "& .MuiListItemText-primary": {
                //                         fontWeight: 400,
                //                       },
                //                       "&:before": {
                //                         display: "none",
                //                       },
                //                       "&:hover": {
                //                         backgroundColor: primaryActiveLight,
                //                       },
                //                     },
                //                   }}
                //                 >
                //                   <ListItemText primary={child?.text} />
                //                 </ListItemButton>
                //               ))}
                //             </List>
                //           </Collapse>
                //         </Box>
                //       )} */}
                //     </>
                //   );
                // }
                return (
                  <ListItemButton
                    onClick={() => {
                      if (!toggle) {
                        setToggle(true);
                      }
                      handleRoute(item?.link);
                    }}
                    selected={location?.pathname === item?.link}
                    key={item?.id}
                  >
                    <ListItemIcon>
                      <item.icon color="currentColor" />
                    </ListItemIcon>
                    {toggle && <ListItemText primary={item?.text} />}
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>
      {toggle && (
        <Box sx={classes.footer}>
          <Typography>Copyright &copy; {new Date().getFullYear()}.</Typography>
          <Typography>All rights reserved</Typography>
          <Typography>IP address: 103.208.71.156</Typography>
        </Box>
      )}
    </Box>
  );
};
