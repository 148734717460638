import {
  FormControl,
  FormLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { useField } from "formik";

export interface InputBoxProps extends Omit<TextFieldProps, "variant"> {
  name: string;
}

export const InputBox: React.FC<InputBoxProps & TextFieldProps> = ({
  onChange,
  label,
  error,
  required,
  name,
  value,
  type,
  fullWidth,
  helperText,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name, type });
  return (
    <FormControl fullWidth={fullWidth}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <TextField
        {...field}
        {...rest}
        type={type}
        fullWidth={fullWidth}
        error={error ?? (meta.touched && !!meta.error)}
        required={required}
        onChange={(e) => {
          !meta.touched && helpers.setTouched(true);
          onChange?.(e);
          field?.onChange(e);
        }}
        helperText={helperText ?? (meta.touched ? meta.error : "")}
        value={value ?? field.value ?? ""}
      />
    </FormControl>
  );
};
