import { Box, SxProps, Tab, Tabs } from "@mui/material";
import React, { useRef } from "react";
import BasicDetails from "./BasicDetails";
import ContactDetails from "./ContactPerson";
import Documents from "./Documents";
import Pricing from "./Pricing";
import Branding from "./Branding";
import { fetchTenantDetails } from "../../services";
import { Loader } from "../../shared/Components";
import { toast } from "react-hot-toast";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabStyles: SxProps = {
  borderBottom: "2px solid #002F5F",
  minHeight: "34px",
  "& .MuiTabScrollButton-root.Mui-disabled": {
    opacity: 0.2,
  },
  "& .MuiTabs-flexContainer": {
    gap: "2px",
  },
  "& .MuiTab-root": {
    textTransform: "none",
    fontSize: "0.875rem",
    color: "#121212",
    padding: "0 1rem",
    minHeight: "34px",
    background: "#E6EDFF",
    borderRadius: "0.5rem 0.5rem 0 0 ",
    alignItems: "flex-start",

    "&.Mui-selected": {
      color: "#fff",
      // fontWeight: 700,
      background: "#002F5F",
    },
  },

  "& .MuiTabs-indicator": {
    display: "none",
    backgroundColor: "#002F5F",
  },
};

const CreateTenant = ({
  tenantId,
  onSuccess,
}: {
  tenantId: string;
  onSuccess: () => void;
}) => {
  const isEdit = !!tenantId;

  const [loading, setLoading] = React.useState(isEdit);
  const [value, setValue] = React.useState(0);
  const valueRef = useRef({});
  const initialValueRef = useRef({});
  const completedTabs = React.useRef<Array<number>>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit =
    (tabIndex: number, isFinalSubmit: boolean = false) =>
    (values: Record<string, any>, message: string) => {
      valueRef.current = { ...valueRef.current, ...values };
      if (!completedTabs.current.includes(tabIndex)) {
        completedTabs.current.push(...[tabIndex, tabIndex + 1]);
      }
      if (!isFinalSubmit) {
        setValue((p) => ++p);
      }

      toast.success(message || "Tenant Updated Successfully");
    };

  React.useEffect(() => {
    if (isEdit) {
      setLoading(true);
      fetchTenantDetails(tenantId)
        .then((res) => {
          valueRef.current = res.data.data;
          initialValueRef.current = res.data.data;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tenantId, isEdit]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            sx={TabStyles}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Basic Details"
              disabled={!isEdit && !completedTabs.current.includes(0)}
              {...a11yProps(0)}
            />
            <Tab
              label="Contact Details"
              disabled={!isEdit && !completedTabs.current.includes(0)}
              {...a11yProps(1)}
            />
            <Tab
              label="Documents"
              disabled={!isEdit && !completedTabs.current.includes(0)}
              {...a11yProps(2)}
            />
            {/* <Tab
              label="Permissions"
              disabled={!isEdit && !completedTabs.current.includes(3)}
              {...a11yProps(3)}
            /> */}
            <Tab
              label="Pricing"
              disabled={!isEdit && !completedTabs.current.includes(0)}
              {...a11yProps(3)}
            />
            <Tab
              label="Branding"
              disabled={!isEdit && !completedTabs.current.includes(0)}
              {...a11yProps(4)}
            />
          </Tabs>

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              height: "calc(100% - 36px)",
            }}
          >
            <Box
              pt={3}
              sx={{
                height: "100%",
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <BasicDetails
                  initialValues={valueRef.current}
                  onSuccess={(values: Record<string, any>, message: string) => {
                    onSuccess();
                    onSubmit(0)(values, message);
                  }}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ContactDetails
                  initialValues={valueRef.current}
                  onSuccess={onSubmit(1)}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Documents
                  initialValues={valueRef.current}
                  onSuccess={onSubmit(2)}
                />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={3}>
                <Permission
                  initialValues={valueRef.current}
                  onSubmit={onSubmit(3)}
                />
              </CustomTabPanel> */}
              <CustomTabPanel value={value} index={3}>
                <Pricing
                  initialValues={valueRef.current}
                  onSuccess={onSubmit(3)}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={4}>
                <Branding
                  initialValues={valueRef.current}
                  onSuccess={onSubmit(4, true)}
                />
              </CustomTabPanel>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CreateTenant;
