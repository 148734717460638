import * as routePaths from "./RouteConstants";
import {
  Login,
  Tenants,
  Dashboard,
  RolesAndPermission,
  Forms,
  FormBuilder,
} from "../pages";
import { Navigate, RouteObject } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../contexts";

const PrivateRoutes = ({ children }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;

  if (!isLoggedIn) {
    return <Navigate to={routePaths.LOGIN} />;
  }

  return <>{!isLoading ? children : null}</>;
};

const PublicRoutes = ({ children }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;

  if (isLoggedIn) {
    return <Navigate to={routePaths.DASHBOARD} />;
  }

  return <>{!isLoading ? children : null}</>;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.LOGIN,
        element: (
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        ),
      },
      // {
      //   path: routePaths.FORGOT_PASSWORD,
      //   element: (
      //     <PublicRoutes>
      //       <ForgotPassword />
      //     </PublicRoutes>
      //   ),
      // },
      {
        path: routePaths.DASHBOARD,
        element: (
          <PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>
        ),
      },
      {
        path: routePaths.TENANTS,
        element: (
          <PrivateRoutes verified={false}>
            <Tenants />
          </PrivateRoutes>
        ),
      },
      {
        path: routePaths.ROLES_AND_PERMISSION,
        element: (
          <PrivateRoutes>
            <RolesAndPermission />
          </PrivateRoutes>
        ),
      },
      {
        path: routePaths.FORM_BUILDER,
        element: (
          <PrivateRoutes>
            <Forms />
          </PrivateRoutes>
        ),
      },
      {
        path: routePaths.FORM_BUILDER_ADD,
        element: (
          <PrivateRoutes>
            <FormBuilder />
          </PrivateRoutes>
        ),
      },
    ],
  },
  {
    path: "*",
    element: "Page not found",
  },
];
