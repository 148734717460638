import React, { useState } from "react";

import { Editor as MonacoEditor } from "@monaco-editor/react";
import {
  DEFAULT_LANGUAGE,
  DEFAULT_FORM_SCHEMA,
  EDITOR_OPTIONS,
} from "./configs";

interface Props {
  height?: number | string;
  width?: number | string;
  defaultValue?: string;
  defaultLanguage?: string;
  onChange?: (
    value?: string,
    event?: React.ChangeEvent<HTMLDivElement>
  ) => void;
}

export const Editor = ({
  height = "100%",
  width = "100%",
  defaultValue = DEFAULT_FORM_SCHEMA,
  defaultLanguage = DEFAULT_LANGUAGE,
  onChange,
}: Props) => {
  const [editor, setEditor] = useState<any>(null);

  React.useEffect(() => {
    editor?.updateOptions?.({ tabSize: 4 });
  }, [editor]);
  return (
    <MonacoEditor
      height={height}
      width={width}
      onMount={(editor) => {
        setEditor(editor);
      }}
      options={EDITOR_OPTIONS}
      theme="vs-light"
      defaultLanguage={defaultLanguage}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
